jQuery(function ($) {

    $( 'header .header-video .embed-responsive' ).first().addClass( 'active' ); 
    $( '.btn-video-lang' ).first().addClass( 'active' );

    $( '.btn-video-lang' ).on( 'click tap', function() {
        const lang = $( this ).data( 'lang' );
        $( '.btn-video-lang' ).removeClass( 'active' );

        const iframe = document.querySelector( '.embed-responsive.active iframe' ); 

        iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        $( 'header .header-video .embed-responsive' ).removeClass( 'active' );

        if ( 'en' == lang ) {
            $( 'header .header-video .embed-responsive' ).first().addClass( 'active' );
        } else if ( 'fr' == lang ) {
            $( 'header .header-video .embed-responsive' ).last().addClass( 'active' );
        }
        $( this ).addClass( 'active' );
    } );
  
  } );